document.addEventListener("DOMContentLoaded", function(){
  AOS.init();

  $(".page-header").sticky({ topSpacing: 0 });
  var imJs = {
    m: function (e) {
      imJs.d();
      imJs.methods();
    },
    d: function (e) {
      this._window = $(window),
        this._document = $(document),
        this._body = $('body'),
        this._html = $('html')
    },
    methods: function (e) {
      imJs.sideMenu();
    },
    // side menu desktop
    sideMenu: function () {
      $(document).on('click', '.menutoggler', function () {
        $("#side-bar").addClass("show");
        $('body').addClass("sideMenu-show");
        $("#anywhere-home").addClass("bgshow");
      });
      $(document).on('click', '.close-icon-menu', function () {
        $("#side-bar").removeClass("show");
        $("#anywhere-home").removeClass("bgshow");
        $('body').removeClass("sideMenu-show");
      });
      $(document).on('click', '#anywhere-home', function () {
        $("#side-bar").removeClass("show");
        $("#anywhere-home").removeClass("bgshow");
        $('body').removeClass("sideMenu-show");
      });
      $(document).on('click', '.sideMenu-show .mainmenu li a.nav-link', function () {
        $("#side-bar").removeClass("show");
        $("#anywhere-home").removeClass("bgshow");
        $('body').removeClass("sideMenu-show");
      });
    },
  }
  imJs.m();
});